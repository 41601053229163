import React, { useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import { AppPlugin, APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { UltraContainer } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { ConfigurableProductDetailsFragment, ProductListFragment, useGetRelatedProductsQuery, ProductDetailsFragment, useGetAppQuery, BundleProductDetailsFragment } from '@hooks/api/index'
import { useLoadingData } from '@hooks/UseLoadingData'
import { ProductSlider } from '@organisms/index'
import { BundleProductDetails } from '@organisms/shop/BundleProductDetails'
import { ConfigurableProductDetails } from '@organisms/shop/ConfigurableProductDetails'
import { SimpleProductDetails } from '@organisms/shop/SimpleProductDetails'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimizationParams, SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const RelatedProductsWrapper = styled.div<{ spaceBelow: boolean }>`
  width: 100%;
  overflow: scroll;
  background-color: ${(): string => theme.colors.white.fantasy};
  .recommended {
    ${(props) => props.spaceBelow ? ResponsivePXValue('padding', { mobile: '0 0 96px', tablet: '32px 0', desktop: '16px 104px' }) : ResponsivePXValue('padding', { mobile: '0', tablet: '32px 0 32px', desktop: '16px 104px 0' })}
  }
  .recommended .filter-title {
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '24px', desktop: '24px' })}
    ${ResponsivePXValue('line-height', { mobile: '22px', tablet: '30px', desktop: '30px' })}
  }
`

const MobileNavSpacer = styled.div`
  display: block;
  ${ResponsivePXValue('height', { mobile: '120px', tablet: '0', desktop: '0' })};
  width: 100%;
`

export interface ProductProps {
  product?: ProductDetailsFragment
  loading: boolean
}

export function Product({ product: productData, loading: productLoading }: ProductProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const { state } = useLocation()
  const [hasViewedProduct, setHasViewedProduct] = useState<boolean>(false)
  const navigate = useNavigate()
  const events = useEvents()

  const { data: relatedProductsData } = useGetRelatedProductsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      slug: productData?.urlKey,
    },
    skip: !productData,
  })

  const product = useLoadingData<ProductDetailsFragment>({ data: productData, loading: productLoading, defaultData: null })

  const _handleGoBack = (): void => {
    navigate(-1)
  }

  useEffect(() => {
    if (!appData.app.isNativeApp) {
      if (appData.app.deviceType === DeviceTypeEnum.MOBILE) {
        AppPlugin.shared().hideFooter()
      } else if (appData.app.deviceType === DeviceTypeEnum.DESKTOP) {
        AppPlugin.shared().showFooter()
      }
      return () => {
        AppPlugin.shared().showFooter()
      }
    }
  }, [appData.app.deviceType])

  useEffect(() => {
    if (productData && !hasViewedProduct) {
      setHasViewedProduct(true)
      events.hasViewedProduct(productData, state)
    }
  }, [productData])

  const seo: SearchEngineOptimizationParams = {
    name: product?.name,
    title: product?.name,
    meta: [], // <---- TODO
  }

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={product?.__typename === 'ConfigurableProduct'}>
          <ConfigurableProductDetails
            product={product as ProductDetailsFragment & ConfigurableProductDetailsFragment}
            loading={productLoading}
            overwriteBreadCrumbs={state}
            onBack={_handleGoBack} />
        </When>
        <When condition={product?.__typename === 'BundleProduct'}>
          <BundleProductDetails
            product={product as ProductDetailsFragment & BundleProductDetailsFragment}
            loading={productLoading}
            overwriteBreadCrumbs={state}
            onBack={_handleGoBack} />
        </When>
        <Otherwise>
          <SimpleProductDetails
            product={product}
            loading={productLoading}
            overwriteBreadCrumbs={state}
            onBack={_handleGoBack} />
        </Otherwise>
      </Choose>

      <If condition={relatedProductsData?.product?.relatedProducts.length > 0}>
        <RelatedProductsWrapper spaceBelow={(relatedProductsData?.product?.upsellProducts.length === 0 || relatedProductsData?.product?.crosssellProducts.length === 0)}>
          <UltraContainer className='recommended'>
            <ProductSlider
              showCount={false}
              title='Recommended Products'
              products={relatedProductsData?.product?.relatedProducts as unknown as ProductListFragment[]}
              total={relatedProductsData?.product?.relatedProducts.length} />
          </UltraContainer>
        </RelatedProductsWrapper>
      </If>

      <If condition={relatedProductsData?.product?.upsellProducts.length > 0}>
        <RelatedProductsWrapper spaceBelow={relatedProductsData?.product?.crosssellProducts.length === 0}>
          <UltraContainer className='recommended'>
            <ProductSlider
              showCount={false}
              title='You might also like'
              products={relatedProductsData?.product?.upsellProducts as unknown as ProductListFragment[]}
              total={relatedProductsData?.product?.upsellProducts.length} />
          </UltraContainer>
        </RelatedProductsWrapper>
      </If>

      <MobileNavSpacer />
    </>
  )

}
