import React, { useEffect, useMemo, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { LayoutSizeClass, useTheme } from 'styled-components'

import { FavouriteButton } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { Rating, ProductLabels, ProductLabel } from '@atoms/index'
import { layoutColWidthForGrid, Skeleton, SkeletonNode } from '@atoms/layout'
import { Link, Paragraph, Tag, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation, ProductListFragment, WarehouseFragment, useAddProductToCartMutation, BundleProductListFragment, useAddBundleProductToCartMutation, BreadcrumbFragment } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { useSimpleToasts } from '@simple/toasts'
import { BundleOptionInput, ProductStockStatus } from '@uctypes/api/globalTypes'

import { AddToCartDisplayTypeEnum, AddToCartButton } from './AddToCartButton'

type ProductListLabel = ProductListFragment['labels'][0]

const QUANTITY_CHANGE_TIMEOUT = 2000

const Container = styled.div<{ size: LayoutSizeClass }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
  ${ResponsivePXValue('width', { mobile: '157px', tablet: '217px', desktop: '217px' })}

  .button {
    width: 100%;
  }

  .product-card-favourite {
    position: absolute;
    ${ResponsivePXValue('top', '12px')}
    ${ResponsivePXValue('right', '12px')}
  }

  .product-card-button{
    ${ResponsivePXValue('border-radius', '4px')}
  }
`

const CardLink = styled.a`
  text-decoration: none;
  white-space: normal;

  .elipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    ${ResponsivePXValue('-webkit-line-clamp', { mobile: '2', tablet: '3', desktop: '3' })}
    ${ResponsivePXValue('padding-bottom', '2px')}
    margin: 0;
  }
`

const ImageContainer = styled.div<{ size: LayoutSizeClass }>`
  cursor: pointer;
  width: 100%;

  ${ResponsivePXValue('padding', '12px')}
  ${ResponsivePXValue('height', { mobile: '146px', tablet: '146px', desktop: '146px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '0', tablet: '8px', desktop: '8px' })}
  
`

const ContentContainer = styled.div<{ isPreviouslyBroughtItem: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('padding', ' 0 12px 12px')}
  ${(props): string => {
    if (props.isPreviouslyBroughtItem) {
      return ResponsivePXValue('height', { mobile: '222px', tablet: '232px', desktop: '232px' })
    }
    return ResponsivePXValue('height', { mobile: '192px', tablet: '202px', desktop: '232px' })
  }}
  

  .brand-name {
    ${ResponsivePXValue('margin-top', '4px')}
  }
`

const BottomContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${ResponsivePXValue('gap', '4px')}
`

const ReviewLinkContainer = styled.a`
  text-decoration: none;
  color: inherit;
`

const RatingContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '20px')}
  ${ResponsivePXValue('gap', '2px')}

  .rating-tag {
    margin: 0;
  }
`

const CustomerReviewContainer = styled.div`

  
`

const CustomerReview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .customerName {
    font-weight: 700;
  }
  
  .customerReview {
    ${ResponsivePXValue('margin-right', '8px')}
    
  }
`

const CustomerReviewTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

const PriceContainer = styled.div`
  flex-grow: 1;
  ${ResponsivePXValue('margin', '7px 0')}
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .discount {
    ${ResponsivePXValue('margin', '0 5px 0 0')}
  }
  .price {
    margin: 0;
  }
`

const LoadingOverlay = styled.div<{ active: boolean }>`
  display: ${(props): string => props.active ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5)};
`

const StockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('gap', '6px')}
  ${ResponsivePXValue('margin', '6px 0 4px')}

  .stock-label {
    flex-shrink: 0;
  }
`

const StockItem = styled.div`
  ${ResponsivePXValue('flex-grow', { mobile: '1', tablet: '0', desktop: '0' })}
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'open-sans';
  font-weight: 600;
  ${ResponsivePXValue('font-size', { mobile: '9px', tablet: '11px', desktop: '11px' })}
  background-color: ${(props): string => props.theme.colors.grey.athens};
  color: ${(props): string => props.theme.colors.grey.stormDust};
  ${ResponsivePXValue('padding', { mobile: '6px 0', tablet: '8px', desktop: '8px' })}
`

const Spacer = styled.div`
  width: 100%;
  flex-grow: 1;
`

export interface ProductCardProps {
  product?: ProductListFragment
  disabled?: boolean
  basePath?: string
  overwriteBreadCrumbs?: BreadcrumbFragment[]
  isPreviouslyBroughtItem?: boolean
  lastPurchaseDate?: string
}

interface ProductCardState {
  newAmount: number | null
}

const DEFAULT_STATE: ProductCardState = {
  newAmount: null,
}

export function ProductCard({ product, disabled, basePath = '', overwriteBreadCrumbs, isPreviouslyBroughtItem = false, lastPurchaseDate = null }: ProductCardProps): JSX.Element {

  const events = useEvents()
  const size = layoutColWidthForGrid({ mobile: 5.2, tablet: 3.6, desktop: 2.30 })

  if (!product) {
    return (
      <Container size={size}>
        <Skeleton
          height={{ mobile: '340px', tablet: '350px', desktop: '350px' }}
          padding={{ mobile: '8px', tablet: '8px', desktop: '8px' }}
          direction='column'
          align='flex-start'
          gap='10px'>
          <SkeletonNode
            height={{ mobile: '120px', tablet: '150px', desktop: '150px' }}
            padding={{ mobile: '4px', tablet: '8px', desktop: '8px' }}>
            <SkeletonNode
              color='gallery'
              shape='rounded'
              width={{ mobile: '120px', tablet: '120px', desktop: '120px' }}
              height={{ mobile: '120px', tablet: '120px', desktop: '120px' }} />
          </SkeletonNode>
          <SkeletonNode
            color='gallery'
            padding={{ mobile: '8px', tablet: '8px', desktop: '8px' }}
            height={{ mobile: '120px', tablet: '50px', desktop: '120px' }} />
          <SkeletonNode
            color='gallery'
            shape='rounded'
            width={{ mobile: '130px', tablet: '200px', desktop: '200px' }}
            height={{ mobile: '35px', tablet: '30px', desktop: '30px' }} />
        </Skeleton>
      </Container>
    )
  }

  const [state, setState] = useState<ProductCardState>({ ...DEFAULT_STATE })
  const [addSimpleProduct, { loading: addSimpleLoading }] = useAddProductToCartMutation()
  const [addBundleProduct, { loading: addBundleLoading }] = useAddBundleProductToCartMutation()
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()
  const [changeQuantity, { loading: quantityLoading }] = useChangeCartItemQuantityMutation()
  const navigate = useNavigate()
  const { addToast } = useSimpleToasts()
  const { cartId } = useCartId()
  const updateTimerRef = useRef(null)
  const theme = useTheme()

  const shoulsShowAddToCart = useMemo(() => {
    if (product.__typename === 'ConfigurableProduct') {
      return false
    }
    if (product.__typename === 'BundleProduct') {
      for (let i = 0; i < product.items.length; i++) {
        if (!product.items[i].required) {
          return false
        }
        if (product.items[i].options.length > 1) {
          return false
        }
        for (let o = 0; o < product.items[i].options.length; o++) {
          if (product.items[i].options[o].canChangeQuantity) {
            return false
          }
        }
      }
    }
    return true
  }, [product])

  const addProduct = async (): Promise<void> => {
    try {
      if (product.__typename === 'BundleProduct') {
        await addBundleProductToCart()
      } else {
        await addSimpleProductToCart()
      }
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const addSimpleProductToCart = async (): Promise<void> => {
    const response = await addSimpleProduct({
      variables: {
        cartId,
        sku: product.sku,
        quantity: 1,
      },
    })
    if (response?.data?.addProduct?.userErrors?.length) {
      response?.data?.addProduct?.userErrors?.forEach?.(({ message }) => addToast({
        message,
        appearance: 'error',
      }))
    }
  }

  const addBundleProductToCart = async (): Promise<void> => {
    const bundleProduct = product as BundleProductListFragment
    const bundleOptions: BundleOptionInput[] = []
    bundleProduct.items.forEach((itm) => {
      itm.options.forEach((opt) => {
        bundleOptions.push({
          id: itm.optionId,
          quantity: opt.quantity,
          value: [opt.id + ''],
        })
      })
    })
    await addBundleProduct({
      variables: {
        input: {
          cartId,
          cartItems: [{
            bundleOptions,
            data: {
              quantity: 1,
              sku: product.sku,
            },
          }],
        },
      },
    })
  }

  const removeProduct = async (): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          cartId,
          cartItemUid: product.cartItemUid,
        },
      })
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    } finally {
      setState((prevState) => update(prevState, {
        newAmount: { $set: null },
      }))
    }
  }

  const changeProduct = async (): Promise<void> => {
    updateTimerRef.current = setTimeout(async () => {
      try {
        await changeQuantity({
          variables: {
            cartId,
            cartItemUid: product.cartItemUid,
            quantity: state.newAmount,
          },
        })
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      } finally {
        setState((prevState) => update(prevState, {
          newAmount: { $set: null },
        }))
      }
    }, QUANTITY_CHANGE_TIMEOUT)
  }

  const updateProduct = async (): Promise<void> => {
    if (updateTimerRef.current) {
      clearTimeout(updateTimerRef.current)
    }
    if (!product.quantityInCart) {
      await addProduct()
    } else if (product.quantityInCart && state.newAmount === 0) {
      removeProduct()
    } else {
      changeProduct()
    }
  }

  const openDetails = (includeReviewsSection = false): void => {
    let stateOptions: { state: BreadcrumbFragment[] | null } = {
      state: null,
    }
    if (overwriteBreadCrumbs) {
      stateOptions = {
        state: overwriteBreadCrumbs,
      }
    }
    events.hasSelectedItem(product)
    const productUrl = `${basePath}/${product.urlKey}${includeReviewsSection ? '?section=Reviews' : ''}`
    navigate(productUrl, stateOptions)
  }

  const _handleReviewLink = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    openDetails(true)
  }

  const _handleAddProduct = async (): Promise<void> => {
    if (!shoulsShowAddToCart) {
      openDetails()
    } else {
      await addProduct()
    }
  }

  const currentStock = product?.stockInfo?.sources?.reduce?.((acc, source) => {
    return acc + source.qty
  }, 0)

  const _handleIncreaseProduct = async (): Promise<void> => {
    const isStateNewAmountNull = state.newAmount === null
    const quantityInCart = isStateNewAmountNull ? product.quantityInCart : state.newAmount

    if (quantityInCart + 1 <= currentStock) {
      const newAmount = quantityInCart + 1
      setState((prevState) => update(prevState, { newAmount: { $set: newAmount } }))
    } else {
      addToast({
        message: `There are only ${currentStock} items in stock`,
        appearance: 'error',
      })
    }
  }

  const _handleDecreaseProduct = async (): Promise<void> => {
    let newAmount = state.newAmount === null ? product.quantityInCart - 1 : state.newAmount - 1

    if (newAmount === 0) {
      addToast({
        message: `Successfully removed ${product.name} from cart`,
        appearance: 'success',
      })
    }

    if (newAmount < 0) {
      newAmount = 0
    }
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleLink = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    e?.preventDefault()
    openDetails()
  }

  const _getTimeSinceLastOrderedDate = (lastOrderedDate: string): string => {
    const now = new Date()
    const daysDiff = Math.floor((now.getTime() - new Date(lastOrderedDate).getTime()) / (1000 * 60 * 60 * 24))

    if (daysDiff >= 0 && daysDiff <= 20) {
      return `${daysDiff} days`
    } else if (daysDiff > 20 && daysDiff <= 49) {
      const weeksDiff = Math.floor(daysDiff / 7)
      return `${weeksDiff} weeks`
    } else if (daysDiff > 49 && daysDiff <= 89) {
      return '2 months'
    } else {
      return '+3 months'
    }
  }

  useEffect(() => {
    if (state.newAmount !== null) {
      updateProduct()
    }
  }, [state.newAmount])

  let price = `${SiteHelper.formatCurrency(product.priceRange.minimumPrice.finalPrice)}`
  let regularPrice = ''

  if (product.__typename === 'ConfigurableProduct' && product.variants?.length) {
    let min = product.variants?.[0]
    product?.variants?.forEach?.((vari) => {
      if (vari.product.priceRange.minimumPrice.finalPrice.value < min.product.priceRange.minimumPrice.finalPrice.value) {
        min = vari
      }
    })
    price = `${SiteHelper.formatCurrency(min.product.priceRange.minimumPrice.finalPrice)}`
  } else if (product.priceRange.maximumPrice.finalPrice.value !== product.priceRange.maximumPrice.regularPrice.value) {
    regularPrice = `${SiteHelper.formatCurrency(product.priceRange.maximumPrice.regularPrice)}`
  }

  let label: ProductListLabel
  let labelIndex: number

  let warehouse!: WarehouseFragment
  let warehouseIndex!: number

  const loading = addSimpleLoading || addBundleLoading || removeLoading || quantityLoading
  const displayType = product?.stockStatus === ProductStockStatus.OUT_OF_STOCK
    ? AddToCartDisplayTypeEnum.OUT_OF_STOCK
    : !shoulsShowAddToCart
      ? AddToCartDisplayTypeEnum.SELECT_VARIANT
      : product?.quantityInCart
        ? AddToCartDisplayTypeEnum.QUANTITY
        : AddToCartDisplayTypeEnum.ADD

  return (
    <Container size={size}>
      <CardLink href={`${basePath}/${product?.urlKey}`} onClick={_handleLink}>
        <ImageContainer size={size}>
          <ResponsiveImage image={product?.coverImage} objectFit='contain' />
          <If condition={!!product?.labels && product?.labels?.length > 0}>
            <ProductLabels>
              <For each='label' of={product?.labels || []} index='labelIndex'>
                {/** TODO: If ever we need to set the max labels for display then it will need to be implemented here. Default is 3 */}
                <If condition={labelIndex < 3}>
                  <ProductLabel key={labelIndex} color={label.labelForegroundColor} backgroundColor={label.labelColor}>{label.label}</ProductLabel>
                </If>
              </For>
            </ProductLabels>
          </If>
        </ImageContainer>
      </CardLink>
      <FavouriteButton product={product} className='product-card-favourite' />
      <ContentContainer isPreviouslyBroughtItem={isPreviouslyBroughtItem}>
        <CardLink href={`${basePath}/${product?.urlKey}`} onClick={_handleLink}>
          <Title
            variant='t10'
            className='elipsis'>
            {product.name}
          </Title>
        </CardLink>
        <If condition={!!product.brand}>
          <Paragraph variant='p2' className='brand-name'>
            <Link className='product-card-brand-link' href={product.brand.path}>{product.brand.name}</Link>
          </Paragraph>
        </If>
        <Spacer />
        <If condition={product.__typename === 'SimpleProduct'}>
          <StockContainer>
            <Paragraph variant='p3' bold className='stock-label'>
              Stock:
            </Paragraph>
            <Choose>
              <When condition={product.stockInfo?.display === 'Out of stock'}>
                <StockItem>CPT: 0</StockItem>
                <StockItem>JHB: 0</StockItem>
              </When>
              <Otherwise>
                <For each='warehouse' of={product.stockInfo?.sources || []} index='warehouseIndex'>
                  <StockItem key={warehouseIndex}>
                    {warehouse.location}: {product.stockStatus === ProductStockStatus.IN_STOCK ? warehouse.qty : 0}
                  </StockItem>
                </For>
              </Otherwise>
            </Choose>
          </StockContainer>
        </If>
        <BottomContentContainer>
          <If condition={!!product?.ratingSummary}>
            <ReviewLinkContainer href={`${basePath}/${product?.urlKey}?section=Reviews`} onClick={_handleReviewLink}>
              <RatingContainer>
                <Rating rating={product.ratingSummary.averageRating} starCount={1} size='small' />
                <Tag variant='t2' className='rating-tag' color={theme.colors.green.greenVogue}>{product.ratingSummary.averageRating} ({product.reviewCount})</Tag>
              </RatingContainer>
            </ReviewLinkContainer>

          </If>
          <If condition={isPreviouslyBroughtItem}>
            <Paragraph variant='p2'><strong className='customerName'>{_getTimeSinceLastOrderedDate(lastPurchaseDate)}</strong> since last purchase</Paragraph>
          </If>
          {/* TODO: UNCOMMENT ONCE FUUNCTIONALITY TO TRACK IF CUSTOMER HAS REVIEWED RESPECTIVE ITEM  BECOMES AVAILABLE FROM BE */}

          {/* <CustomerReviewContainer>
            <CustomerReview>
              <CustomerReviewTop>
                <Choose>
                  <When condition={hasBeenReviewed}>
                    <Paragraph
                      variant='p2'
                      color={theme.colors.green.bottleGreen}
                      className='customerReview'><strong className='customerName'>{customerData?.currentCustomer?.firstname}&apos;s</strong> Review</Paragraph>
                    <Rating starCount={5} size='small' rating={5} />
                  </When>
                  <Otherwise>
                    <Link
                      href={`${basePath}/${product?.urlKey}`}
                      onClick={_handleLink}
                      decoration='underline'
                      color={theme.colors.orange.casablanca}>
                        Write a review
                    </Link>
                  </Otherwise>
                </Choose>

              </CustomerReviewTop>

            </CustomerReview>
          </CustomerReviewContainer> */}
          <PriceContainer>
            <Choose>
              <When condition={regularPrice !== ''}>
                <Paragraph
                  color={theme.colors.grey.gunSmoke}
                  variant='p2'
                  className='discount'
                  decoration='line-through'>
                  {regularPrice}
                </Paragraph>
                <Title variant='t5' className='price'>{price}</Title>
              </When>
              <When condition={product?.__typename === 'ConfigurableProduct'}>
                <Paragraph
                  color={theme.colors.grey.gunSmoke}
                  variant='p2'
                  className='discount'>
                  From
                </Paragraph>
                <Title variant='t5' className='price'>{price}</Title>
              </When>
              <Otherwise>
                <Title variant='t5' className='price'>{price}</Title>
              </Otherwise>
            </Choose>
          </PriceContainer>
        </BottomContentContainer>
        <AddToCartButton
          className='product-card-button'
          variant='card'
          displayType={displayType}
          currentQuantity={state.newAmount || product?.quantityInCart}
          loading={loading}
          onAdd={_handleAddProduct}
          onIncrease={_handleIncreaseProduct}
          onDecrease={_handleDecreaseProduct} />
      </ContentContainer>
      <LoadingOverlay active={disabled} />

    </Container>
  )

}
