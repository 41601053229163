import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageMyDiscountsSectionFragment, PersonalDiscountFragment, ProductListFragment, useCustomerQuery, useGetAppQuery, usePersonalDiscountsLazyQuery } from '@hooks/api'
import { PageBanner } from '@molecules/content/PageBanner'
import { ProductSlider } from '@organisms/shop/ProductSlider'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', '32px')}
`

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${ResponsivePXValue('gap', '16px')}
  background-color: ${(props): string => props.backgroundColor};


  .block-title {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

  .block-paragraph {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const CustomerPromoContainer = styled.div`
  ${(props): string => ResponsivePXValue('background-color', { mobile: props.theme.colors.white.pureWhite })}
  ${ResponsivePXValue('padding', { mobile: '8px 16px' })}

`

const PromoDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'flex-end', desktop: 'flex-end' })}
`

const Details = styled.div`
  width: 100%;
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column' })}
  ${ResponsivePXValue('gap', { mobile: '12px' })}
  ${ResponsivePXValue('margin-top', { mobile: '8px' })}
`

const PromoCodeChip = styled.span`
  border: 2px solid ${(props): string => props.theme.colors.green.viridian}; 
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  color: ${(props): string => props.theme.colors.green.viridian};
  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '20px', desktop: '22px' })}  
  border-radius: 16px; 
  ${ResponsivePXValue('padding', '5px')}
  ${ResponsivePXValue('margin-left', { desktop: '24px' })}
  cursor: pointer;
`

export interface PageMyDiscountsSectionProps {
  section: PageMyDiscountsSectionFragment
  showPlaceholder?: boolean
}

enum DisplayTypeEnum {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  DISCOUNTS = 'DISCOUNTS',
}

interface PageMyDiscountsSectionState {
  discounts: PersonalDiscountFragment[]
  displayType: DisplayTypeEnum
}

const DEFAULT_STATE: PageMyDiscountsSectionState = {
  discounts: [],
  displayType: DisplayTypeEnum.LOADING,
}

export function PageMyDiscountsSection({ section, showPlaceholder = false }: PageMyDiscountsSectionProps): JSX.Element {

  const theme = useTheme()
  const [state, setState] = useState<PageMyDiscountsSectionState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()
  const { data: customerData, loading: customerLoding } = useCustomerQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [fetchPersonalDiscounts] = usePersonalDiscountsLazyQuery()
  const navigate = useNavigate()

  const getPersonalDiscounts = async (): Promise<void> => {
    const result = await fetchPersonalDiscounts()
    const discounts = result.data?.personalDiscounts
    if (discounts.length) {
      setState((prevState) => update(prevState, {
        discounts: {
          $set: discounts,
        },
        displayType: {
          $set: DisplayTypeEnum.DISCOUNTS,
        },
      }))
    } else {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.EMPTY,
        },
      }))
    }
  }

  const _handleViewAll = (): void => {
    navigate('/my-ftn-shop/my-discounts')
  }

  const _handleCopyCode = (code: string): void => {
    navigator.clipboard.writeText(code)
    addToast({
      message: 'Your personal discount code has been copied to your clipboard.',
      appearance: 'success',
    })
  }

  useEffect(() => {
    if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.LOADING,
        },
      }))
      getPersonalDiscounts()
    } else if (!customerLoding) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.EMPTY,
        },
      }))
    }
  }, [customerData])

  const { MOBILE } = DeviceTypeEnum

  let discount: PersonalDiscountFragment

  if (state.displayType === DisplayTypeEnum.EMPTY && !showPlaceholder && !section.placeholderBanner) {
    return null
  }

  return (
    
    <Container backgroundColor={section?.backgroundColor}>
      <Choose>
        <When condition={state.displayType === DisplayTypeEnum.LOADING}>
          <Loader />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && !!section.placeholderBanner}>
          <PageBanner pageBanner={section.placeholderBanner} />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && showPlaceholder}>
          <Placeholder>
            <Title variant='t3'>My Discounts Placeholder</Title>
            <Paragraph>There is no preview content for this section while the user is logged out or has no personal discounts</Paragraph>
          </Placeholder>
        </When>
        <Otherwise>
            <HeadingContainer>
              <Title variant='t1' className='block-title'>
                Discounts for {customerData?.currentCustomer?.firstname}
              </Title>
              <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='ghost' size='medium'/>
            </HeadingContainer>
            <For each='discount' of={state.discounts || []}>
              <CustomerPromoContainer>
                <Heading variant='h5' >Get {discount?.percentage}% off</Heading>
                <PromoDetails>
                  <Details>
                    <Paragraph variant={appData.app.deviceType === MOBILE ? 'p3' : 'p1'}>
                          To activate your discounts, copy this unique promo code and paste it into the promo code box at checkout: </Paragraph>
                    <PromoCodeChip onClick={() => _handleCopyCode(discount?.code)}>
                      <Paragraph
                        variant={appData.app.deviceType === MOBILE ? 'p3' : 'p1'}
                        color={theme.colors.green.viridian}>
                        {discount?.code}
                      </Paragraph>
                    </PromoCodeChip>
                  </Details>
                </PromoDetails>
              </CustomerPromoContainer>
                <If condition={discount?.products?.length > 0}> 
                  <ProductSlider
                    showCount={false}
                    total={discount?.products?.length}
                    products={discount?.discountProducts }/>
                </If>
            </For>
        </Otherwise>
      </Choose>
    </Container>
  )
}
